import Cookies from "js-cookie";

const Token = "CERTIFICATE";
const Refresh_Token = "EXCHANGE_CERTIFICATE";

export function getToken() {
    return getCookie(Token);
}

export function getRefreshToken() {
    return getCookie(Refresh_Token);
}

export function getCookie(name) {
    return Cookies.get(name)
}


export function getCookieValue(name) {
    return Cookies.get(name)
}

export function setToken(token) {
    return Cookies.set(Refresh_Token, token);
}

export function getAuth() {
    return Cookies.get(Token);
}

export function setCookieDay(name, token, day) {
    return Cookies.set(name, token, {expires: day});
}


export function removeToken() {
    Cookies.remove(Token);
    return Cookies.remove(Refresh_Token);
}

export function setCookieDayShelf(cname, value, day) {
    let d = new Date();
    d.setTime(d.getTime() + (day * 24 * 60 * 60 * 1000));
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + value + ";" + expires + ";path=/";
}

export function setCookieTime(cname, value, hour) {
    let d = new Date();
    d.setTime(d.getTime() + (hour * 1000));
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + value + ";" + expires + ";path=/";
}

const getCookieShelf = function (cname) {
    let name = cname + "=";
    let ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == " ") {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
};

export function removeCookie(name) {
    return Cookies.remove(name)
}


export function setFirstDomainCookie(name, value, day) {
    let d = new Date();
    d.setTime(d.getTime() + (day * 24 * 60 * 60 * 1000));
    let expires = "expires=" + d.toUTCString();
    document.cookie = name + "=" + value + ";" + expires + ";path=/; domain=pypyai.com";
}

export function deleteFirstDomainCookie(name) {
    document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=pypyai.com";
}