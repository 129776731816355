import {createApp} from 'vue'
import App from './App.vue'
import router from "@/router";
import axios from "axios";
import ElementPlus from "element-plus"
import 'element-plus/dist/index.css'
import '@/assets/iconfont/iconfont'
import '@/assets/iconfont/iconfont.css'
import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'
import elementIcon from "@/plugins/svgIcon"

const app = createApp(App)
app.use(router)
    .use(ElementPlus)
    .use(elementIcon)
    .use(mavonEditor)
    .mount('#app')

app.config.globalProperties.$axios = axios;