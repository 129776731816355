<template>
  <router-view></router-view>
</template>

<script>
// import '@/assets/font/font.css';

export default {
  name: 'App',
  data() {
    return {
      isRouterAlive: true
    }
  },
  provide() { //提供
    return {
      reload: this.reload
    }
  },
  methods: {
    reload() {
      this.isRouterAlive = false
      this.$nextTick(function () {
        this.isRouterAlive = true
      })
    }
  }
}
</script>

<style>
#app {
  /*font-family: Avenir, Helvetica, Arial, sans-serif;*/
  /*-webkit-font-smoothing: antialiased;*/
  /*-moz-osx-font-smoothing: grayscale;*/
  /*text-align: center;*/
  /*color: #2c3e50;*/
  height: 100%;
}

html, body {
  /*height:100%;*/
  padding: 0;
  margin: 0;
}

body {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: 'bb4171'
}

li {
  list-style: none !important;
}
</style>
