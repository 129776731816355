import {createRouter, createWebHistory} from 'vue-router'
import {getCookie} from "@/plugins/auth";
import {login_option} from "@/utils/utils";

const router = createRouter({
    history: createWebHistory(),
    scrollBehavior(to,from,savedPosition){
        if(savedPosition){
            return savedPosition;
        }else{
            return {left:0,top:0}
        }
    },
    routes: [
        {
            path: "/",
            name: "index",
            component: () => import("@/view/index/index.vue"),
        },
        {
            path: "/problem",
            name: "problem",
            component: () => import("@/view/other/problem.vue"),
        },
        {
            path: "/list",
            name: "list",
            component: () => import("@/view/product/productList.vue"),
        },
        // 中间页
        {
            path: "/propaganda",
            name: "propaganda",
            component: () => import("@/view/propaganda/middlePage.vue"),
        },
        {
            path: "/about",
            name: "about",
            component: () => import("@/view/other/aboutus.vue"),
        },
        {
            path: "/enterprise",
            name: "enterprise",
            component: () => import("@/view/other/enterpriseTraining.vue"),
        },
        {
            path: "/classGuide",
            name: "classGuide",
            component: () => import("@/view/other/classGuide.vue"),
        },
        {
            path: "/courseCenter",
            name: "courseCenter",
            meta: {
                needLogin: true  // 需要登录
            },
            component: () => import("@/view/course/courseCenter.vue"),
        },
        {
            path: "/detail",
            name: "detail",
            component: () => import("@/view/propaganda/courseDetail.vue"),
        },
        {
            path: "/chapter",
            name: "chapter",
            meta: {
                needLogin: true  // 需要登录
            },
            component: () => import("@/view/course/courseChapter.vue"),
        },
        {
            path: "/learn_video",
            name: "learn_video",
            meta: {
                needLogin: true  // 需要登录
            },
            component: () => import("@/view/course/learn_video.vue"),
        },
        {
            path: "/learn",
            name: "learn",
            meta: {
                needLogin: true  // 需要登录
            },
            component: () => import("@/view/course/learn_normal.vue"),
        },
        {
            path: "/userCenter",
            name: "userCenter",
            meta: {
                needLogin: true  // 需要登录
            },
            component: () => import("@/view/user/userCenter.vue"),
        },
    ],
})



router.beforeEach(async (to, from, next) => {
    // needLogin  表示哪些路由需要在登录条件下才能访问
    let needLogin = to.matched.some(match => match.meta.needLogin)
    if(needLogin){
        if(getCookie("userId")){
            next()
        }else{
            login_option(location.href)
        }
    }else{
        next()
    }
})
export default router
