import {
    getCookieValue,
    deleteFirstDomainCookie,
    setCookieDay,
    setFirstDomainCookie,
    removeCookie
} from "@/plugins/auth";
import Cookies from "js-cookie";
import {ElMessage} from "element-plus";
import router from "@/router";
import {Base64} from "js-base64";

export function exit_option() {
    deleteUserCookie();
    setCookieDay("exit", "1", 1);
    setFirstDomainCookie("logout", true, 7);
    ElMessage({
        message: '退出登录成功',
        type: 'success'
    })
    router.push({path: "/"})
}

// 清除系统用户cookie
export function deleteUserCookie() {
    Cookies.remove("userId");
    Cookies.remove("CERTIFICATE");
    Cookies.remove("EXCHANGE_CERTIFICATE");
    Cookies.remove("phone");
    Cookies.remove("phoneSign");
    Cookies.remove("userHeadImgUrl");
    Cookies.remove("userName");
    Cookies.remove("emailSign");
    Cookies.remove("weChatSign");
    Cookies.remove("pwdSign");
    Cookies.remove("phoneSign");
    Cookies.remove("CERTIFICATE_ID");
    Cookies.remove("userSign");
    Cookies.remove("SESSION");
    deleteFirstDomainCookie("bing-phone");
    deleteFirstDomainCookie("IDENTITY");
}


export function login_option(value){
    removeCookie("exit");
    localStorage.setItem('pc_url',value);
    location.href='/oauth2/authorization/keycloak'
}

export function decode(value) {
    try {
        return Base64.decode(value)
    } catch {
        return value;
    }
}

export function encode(value) {
    return Base64.encode(value)
}